<template>
    <v-card height="90vh" class="d-flex flex-column justify-center align-center">
        <h1 class="text-center">404</h1>
        <h2 class="text-center">Page introuvable</h2>
        <h3 class="text-center">
            <router-link to="/">Retourner a l'acceuil</router-link>
        </h3>
    </v-card>
</template>

<script>
export default {
    name: "Error404",
}
</script>
